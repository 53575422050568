.nav-container {
  height: 5rem;
  background: linear-gradient(
    137deg,
    rgb(9 30 32),
    rgb(12, 31, 32),
    rgb(5, 18, 19),
    rgb(5, 35, 37)
  );
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0px;
  z-index: 90;
  @media (max-width: 500px) {
    padding: 1rem 0.8rem;
  }
  .logo {
    height: 55px;
    object-fit: cover;
    cursor: pointer;
    mix-blend-mode: screen;
   
  }

  .offer-text {
    font-size: 20px;
    color: #1f95fd;
    font-weight: 500;
    animation: fadeInOut 5s ease-in-out infinite;
    display: flex;
    align-items: center;
    gap: 4px;
    cursor: pointer;

    @media (max-width: 750px) {
      font-size: 16px;
    }
    @media (max-width: 500px) {
      font-size: 14px;
    }
  }

  @keyframes fadeInOut {
    0%,
    100% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
  }

  .desktop-menu {
    display: flex;
    gap: 1.5rem;
 position: relative;
    .item {
      font-size: 18px;
      color: #fff;
      transition: 0.5s;
      padding: 6px;
      font-family: "Times New Roman", Times, serif;
      letter-spacing: 0.5px;
      font-weight: 600;
      cursor: pointer;

      .menu-name {
        border-radius: 12px;
        transition: 0.5s;
        padding: 4px;
        display: flex;
        align-items: center;
        &:hover {
          color: #f09d1b;

          &.showmodal {
            color: red;
          }
        }

        &.active {
          color: #f09d1b;
        }
      }
    }
  }

  .mobile-menu-icon {
    display: none;
    cursor: pointer;
    color: white;
    z-index: 110;
  }
  .mobile-container {
    height: 100vh;
    position: fixed;
    top: 80px;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100vw;

    .mobile-menu-box {
      width: 45%;
      min-width: 360px;
      height: 100%;
      background-color: #333;
      color: white;
      display: flex;
      flex-direction: column;
      z-index: 1000;
      transform: translateX(100%);
      transition: transform 1s ease-in-out;
      background: linear-gradient(
        180deg,
        rgb(9 30 32),
        rgb(12, 31, 32),
        rgb(5, 18, 19),
        rgb(5, 35, 37),
        rgb(8, 51, 55)
      );
      &.active {
        transform: translateX(0);
      }

      .menu-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding:6px ;
        background: linear-gradient(
          180deg,
          rgb(27, 49, 51),
          rgb(9, 67, 71),
          rgb(17, 75, 80),
          rgb(9, 67, 71),
          rgb(27, 49, 51)
        );
     
      }

      .menu-content {
        flex-grow: 1;
        padding: 10px;

        ul {
          list-style: none;
          padding: 0;

          li {
            padding: 6px 20px;
            cursor: pointer;
            font-family: "Times New Roman", Times, serif;
            letter-spacing: 1px;
            font-weight: 600;
            background-color: rgb(0, 82, 88);
            border-radius: 4px;
            box-shadow: 0px 4px 6px rgb(0, 82, 88);
            margin: 8px 0px;
            transition: 0.5s;
            &:hover {
              background-color: rgb(2, 48, 51);
            }
            .dropdown-header {
              display: flex;
              align-items: center;
              justify-content: space-between;
            }
            .service-list {
              height: 340px;
              overflow-y: scroll;
              padding: 5px;
            }
          }
        }
      }
    }
  }
  @media (max-width: 1000px) {
    .desktop-menu {
      display: none;
    }

    .mobile-menu-icon {
      display: block;
    }
  }
}
.offer {
  color: black;
  font-size: 14px;
  background-color: rgb(245, 245, 85);
  padding: 3px 6px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 8px;
}
