/* From Uiverse.io by Priyanshu02020 */
#heart {
  display: none;
}

.like-button {
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.like-icon {
  fill: red;
  height: 28px;
  width: 28px;
}

.like-count {
  color: #000;
  font-size: 24px;
  font-weight: 600;
  font-family: "Raleway", arial, sans-serif;
}
