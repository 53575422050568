.service-details-containers {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 30px;
  // padding: 50px 20px;

  .container {
    background-color: #f4f7fb;
    padding: 50px 20px;
    width: 100%;
    .service-banner {
      display: flex;
      align-items: start;
      justify-content: space-between;
      gap: 5px;
      @media (max-width: 800px) {
        flex-direction: column;
        align-items: center;
        gap: 15px;
      }

      .tex-container {
        display: flex;
        align-items: start;
        justify-content: space-between;
        flex-direction: column;
        gap: 20px;
        width: 70%;
        @media (max-width: 800px) {
          width: 80%;
        }
        .name {
          font-size: 42px;
          color: black;
          letter-spacing: 1px;
          font-weight: 600;
          font-family: "Raleway", arial, sans-serif;
          @media (max-width: 500px) {
            font-size: 35px;
          }
          &.soln {
            color: #f09d1b;
            font-size: 35px;
            font-weight: 500;
            letter-spacing: 0.2px;

            @media (max-width: 500px) {
              font-size: 30px;
            }
          }
          &.about {
            font-size: 26px;
            font-weight: 400;
            letter-spacing: 0.2px;
            @media (max-width: 500px) {
              font-size: 21px;
            }
          }
        }
      }
      .image-container {
        .image {
          width: 300px;
          height: 300px;
          .img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
  }
  .service-list-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    .heading {
      font-size: 36px;
      color: black;
      letter-spacing: 1px;
      font-weight: 600;
      font-family: "Raleway", arial, sans-serif;
      &.main {
        color: #f09d1b;
      }
    }
    .service-list {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      .list-box {
        padding: 12px 5px;
        border-radius: 4px;
        width: 350px;
        background-color: #f4f7fb;

        margin: 8px 4px;
        // box-shadow: rgb(31, 1, 72) 0px 20px 30px -10px;
        border-bottom: 1px solid rgb(31, 1, 72);
        @media (min-width: 1000px) {
          width: 450px;
        }
        .name {
          font-size: 18px;
          color: black;
          letter-spacing: 0.1px;
          font-weight: 600;
          font-family: "Raleway", arial, sans-serif;
        }
      }
    }
  }
}
.faq-container {
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  margin: 10px 0px;

  .heading {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #f09d1b;
    font-size: 35px;
    word-spacing: 1px;
    font-weight: 700;
    margin: 0px 0px 15px 0px;
  }
}
.accordian {
  width: 90%;

  @media (min-width: 1800px) {
    width: 30%;
  }

  @media (min-width: 1400px) {
    width: 40%;
  }

  @media (min-width: 1000px) {
    width: 50%;
  }

  @media (min-width: 600px) {
    width: 70%;
  }
}
.accordion__button {
  color: black !important;
  font-size: 20px;
  font-weight: 600;
  align-items: center;
  word-spacing: 0.5px;

  &:hover {
    color: #f09d1b !important;
  }
}
.accordion__panel {
}

.answer {
  color: black;
  font-size: 18px;
  font-weight: 400;
  width: 100%;
  word-spacing: 0.5px;
  display: flex;
  align-items: start;
  word-wrap: break-word;
  padding-left: 0;
  margin: 2px 0 2px 0;
  text-align: left;

  &:hover {
    color: #f09d1b;
  }
}
