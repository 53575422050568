@import url("./navbar.scss");
@import url("./footer.scss");
@import url("./service.scss");
@import url("./event.scss");
@import url("./about.scss");
@import url("./home.scss");
@import url("./cart.scss");
@import url("./variable.scss");
@import url("./order.scss");
@import url("./admindashboard.scss");
@import url("./product.scss");
@import url("./work.scss");





@import url("./component/modal.scss");
@import url("./component/common.scss");
@import url("./component/card.scss");
@import url("./component/servicedetails.scss");
@import url("./component/Input.scss");

* {
  box-sizing: border-box;
}

::-webkit-scrollbar {
  width: 0px;
}
.slider::-webkit-scrollbar {
  display: none;
}
::-webkit-scrollbar-thumb {
  background-color: white;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

.main {
  position: relative;
}

.loader-main{
  position: fixed;
  z-index: 99;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  
.loading-wave {
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 50%;
  border: 4px solid #fff;
  box-shadow: rgba(255, 255, 255, 0.25) 0px 54px 55px, 
            rgba(255, 255, 255, 0.12) 0px -12px 30px, 
            rgba(255, 255, 255, 0.12) 0px 4px 6px, 
            rgba(255, 255, 255, 0.17) 0px 12px 13px, 
            rgba(255, 255, 255, 0.09) 0px -3px 5px;
  .loading-bar {
    width: 8px;
    height: 2px;
    margin: 0 2px;
    background-color: #7cc8fc;
    border-radius: 5px;
    animation: loading-wave-animation 1s ease-in-out infinite;

    &:nth-child(2) {
      animation-delay: 0.1s;
    }

    &:nth-child(3) {
      animation-delay: 0.2s;
    }

    &:nth-child(4) {
      animation-delay: 0.3s;
    }
  }
}

@keyframes loading-wave-animation {
  0% {
    height: 4px;
  }

  50% {
    height: 30px;
  }

  100% {
    height: 4px;
  }
}

}
.chart-container {
  width: 100%;
  height: 400px;
}

@media (max-width: 768px) {
  .chart-container {
    height: 350px;
  }
}

@media (max-width: 480px) {
  .chart-container {
    height: 250px;
  }
}