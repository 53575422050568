.footer-box {
  width: 100%;
  background: linear-gradient(
    137deg,
    rgb(9 30 32),
    rgb(12, 31, 32),
    rgb(5, 18, 19),
    rgb(5, 35, 37),
    rgb(8, 51, 55)
  );
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  border-top: 2px solid rgb(0, 0, 0, 0.3);
  padding: 5rem 0.5rem 0 0.5rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  @media (max-width: 460px) {
    padding: 3rem 0.5rem;
  }
  .location-item {
    color: #f09d1b;
    font-style: normal;
    font-family: "Times New Roman", Times, serif;
    word-spacing: 1px;
    letter-spacing: 0.6px;
    margin: 6px;
    font-size: 18px;
  }
  .footer-bottom {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    margin-top: 10px;
    padding: 10px;
    background: linear-gradient(
    137deg,
    rgb(9 30 32),
    rgb(3, 7, 7),
    rgb(2, 8, 8),
   
  );
    .text {
      color: white;
      margin: 4px 0;
      position: relative;
      font-size: 18px;
      font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
      @media (max-width: 450px) {
        font-size: 16px;
      }
      .anoop {
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.3s ease, visibility 0.3s ease;

        &:hover {
          opacity: 1;
          visibility: visible;
          font-size: 20px;
          margin-left: 4px;
          font-weight: 400;
          letter-spacing: 1px;
          font-style: italic;
          color: red;
        }
      }

      &:hover .anoop {
        opacity: 1;
        visibility: visible;
        font-size: 20px;
        margin-left: 4px;
        font-weight: 400;
        letter-spacing: 1px;
        font-style: italic;
        color: red;
      }
    }
  }
  .footer-top {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 8px 0px;
    flex-wrap: wrap;
    .footer-info-box {
      margin: 5px;
      display: flex;
      align-items: start;
      justify-content: start;
      flex-direction: column;
      max-width: 225px;
      .heading {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: start;
        font-size: 25px;
        color: #f09d1b;
        text-shadow: 0px 1px 8px #8e4a05;

        font-weight: 550;
        letter-spacing: 0.5px;
        font-style: italic;
        font-family: "Cursive";
        margin-bottom: 12px;
        @media (max-width: 450px) {
          font-size: 20px;
        }
      }

      .contact-info {
        display: flex;
        align-items: start;
        flex-direction: column;
        justify-content: start;
        gap: 4px;

        .contact-box {
          display: flex;
          align-items: center;
          gap: 2px;
          .image {
            width: 18px;
            height: 18px;
            margin-right: 8px;
          }
          .minitext {
            color: white;
            font-size: 15px;

            @media (max-width: 450px) {
              font-size: 13px;
            }
          }
          .text {
            color: white;
            font-size: 17px;

            @media (max-width: 450px) {
              font-size: 15px;
            }
          }
        }
        .open-box {
          display: flex;
          align-items: start;
          flex-direction: column;
          gap: 4px;
          justify-content: start;
          width: 100%;
          .minitext {
            color: white;
            font-size: 15px;
            cursor: pointer;
            @media (max-width: 450px) {
              font-size: 13px;
            }
            &:hover {
              color: #f09d1b;
            }
          }
          .text {
            color: white;
            font-size: 17px;
            font-style: italic;

            @media (max-width: 450px) {
              font-size: 15px;
            }
          }
        }
      }

      .wrapperbox {
        display: flex;
        list-style: none;
        width: 100%;
        font-family: "Poppins", sans-serif;
        justify-content: start;
        align-items: start;
        padding: 4px;
        .icon {
          position: relative;
          background: #fff;
          border-radius: 50%;
          margin: 0px 4px 4px 0px;
          width: 30px;
          height: 30px;
          font-size: 14px;

          display: flex;
          justify-content: center;
          align-items: center;
          box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
          cursor: pointer;
          transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
        }

        .facebook {
          background: #1877f2;
          color: #fff;
          background: linear-gradient(
            95deg,
            #0e386e,
            #1877f2,
            #136adb,
            #1877f2,
            #042f68
          );
        }

        .instagram {
          color: #fff;
          background: linear-gradient(
            75deg,
            #5d1522,
            #dd294a,
            #a51d36,
            #87021a
          );
        }
      }
      .opening-info {
        display: flex;
        align-items: start;
        justify-content: start;
        flex-direction: column;
        width: 100%;
      }
      .contact-box {
        .link {
          color: #f09d1b;
          cursor: pointer;
          margin-left: 2px;
        }
      }
      .google-wrapper {
        display: flex;
        align-items: start;
        gap: 8px;
        .info-wrap {
          display: flex;
          flex-direction: column;
          gap: 4px;
          .title {
            font-size: 14px;
            color: #fff;
            font-weight: 500;
          }
          .review {
            font-size: 12px;
            color: #fff;
            font-weight: 400;
          }
          .powered {
            font-size: 13px;
            color: #fff;
            font-weight: 400;
          }
          .g-btn {
            font-size: 14px;
            color: #fff;
            font-weight: 500;
            cursor: pointer;
            background-color: rgb(5, 45, 47);
            border-radius: 8px;
            padding: 6px;
            width: 100px;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 6px;
          }
        }
      }
    }
    .input-info-box {
      margin: 5px;
      display: flex;
      align-items: start;
      justify-content: start;
      flex-direction: column;
      max-width: 225px;
      .heading {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: start;
        font-size: 25px;
        color: #f09d1b;
        text-shadow: 0px 1px 8px #8e4a05;
        font-weight: 550;
        letter-spacing: 0.5px;
        font-style: italic;
        font-family: "Cursive";
        margin-bottom: 12px;
        @media (max-width: 450px) {
          font-size: 20px;
        }
      }
      .text {
        color: white;
        font-size: 15px;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        text-align: left;
        margin-bottom: 4px;
      }
      .newsletter-form {
        width: 100%;
        max-width: 225px;
        position: relative;

        display: flex;
        align-items: center;
        justify-content: start;
        @media (max-width: 450px) {
          font-size: 13px;
        }
        .inputbox {
          width: 100%;

          height: 40px;
          padding: 2px 12px 2px 6px;
          font-size: 14px;
          border: none;
          background-color: rgb(2, 96, 105);
          display: flex;
          align-items: center;
          border-radius: 4px;
          margin: 4px 0px;
          color: #f09d1b;
          &::placeholder {
            color: #f09d1b;
          }
        }
        .submitbtn {
          position: absolute;
          right: 1px;
          z-index: 2;
          width: 40px;
          height: 35px;
          color: white;
          background-color: rgb(3, 29, 30);
          cursor: pointer;
          padding: 2px;
          border: none;
          font-size: 12px;
          border-radius: 4px;
        }
      }
    }
  }
}
