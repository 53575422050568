.whatsapp-wrapper {
  position: fixed;
  top: calc(100% - 8rem);
  left: 4px;
  width: 50px;
  height: 50px;
  color: white;
  background-color: #29a71a;
  border-radius: 50%;
  z-index: 9999;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  border: none;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  transform: translateX(-80px);
  transition: transform 0.3s ease-in-out;
  @media (min-width: 750px) {
    top: calc(100% - 5rem);
  }
  &.visible {
    transform: translateX(0);
  }
  .chat {
    width: 35px;
    height: 35px;
    border-radius: 50%;
  }
}
.call-wrapper {
  position: fixed;
  top: calc(100% - 4rem);
  left: 4px;
  width: 50px;
  height: 50px;
  color: white;
  background-color: rgb(71, 71, 248);
  border-radius: 50%;
  z-index: 99999;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  border: none;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  transform: translateX(-80px);
  transition: transform 0.3s ease-in-out;
  @media (min-width: 750px) {
    display: none;
  }
  &.visible {
    transform: translateX(0);
  }
}
.main-marker {
  position: fixed;
  z-index: 9999;
}

.appointment-book {
  position: fixed;
  top: calc(100% - 10rem);
  right: 0;
  width: 35px;
  padding: 8px 2px;
  color: white;
  background-color: #fa8510;
  border-radius: 0px 6px 6px 0px;
  z-index: 99999;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  border: none;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  transition: 0.9s ease-in-out;
  writing-mode: vertical-rl;
  text-align: center;
  transform: rotate(180deg);
  white-space: nowrap;
  font-size: 1.2rem;
  letter-spacing: 1px;
}

.booking-modal-main {
  position: fixed;
  z-index: 999999999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  .modal-inside {
    background-color: rgba(214, 252, 255, 0.9);
    border-radius: 10px;
    padding: 12px 2px;
    min-width: 390px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: -4px 6px 12px rgb(54, 2, 129);
    position: relative;
    .cross {
      position: absolute;
      top: 12px;
      right: 16px;
      cursor: pointer;
    }
    .wrapper {
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 10px;
      padding: 24px 0px;
      .contact-para {
        text-align: center;
        font-size: 18px;
        letter-spacing: 0.5px;
        font-family: Arial, Helvetica, sans-serif;
        font-weight: 600;
      }
      .contact-form {
        margin: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 90%;
        //max-width: 80%;

        .name,
        .email,
        .service,
        .phone {
          font-size: medium;
          width: 360px;
          height: 50px;
          max-width: 450px;
          margin: 0.5rem;
          padding: 0.5rem 1rem;
          color: white;
          border: 1px ridge #0ef;
          border-radius: 5px;
          //box-shadow:-4px 6px 12px rgb(54, 2, 129);

          background: linear-gradient(
            180deg,
            rgb(14, 13, 16),
            rgb(23, 1, 54),
            rgb(14, 13, 16)
          );
        }
        .name::placeholder,
        .message::placeholder,
        .email::placeholder,
        .service::placeholder,
        .phone::placeholder {
          color: white;
          font-size: medium;
        }
        .message {
          font-size: medium;
          width: 100%;
          max-width: 450px;
          margin: 0.5rem;
          padding: 0.5rem 1rem;
          color: white;
          border: 1px ridge #0ef;
          border-radius: 5px;
          // box-shadow:-4px 6px 12px rgb(54, 2, 129);

          background: linear-gradient(
            180deg,
            rgb(14, 13, 16),
            rgb(23, 1, 54),
            rgb(14, 13, 16)
          );
        }
        .submitbtn {
          color: #0ef;
          background: linear-gradient(
            45deg,
            rgb(14, 13, 16),
            rgb(31, 1, 72),
            rgb(14, 13, 16)
          );
          border: none;
          cursor: pointer;
          padding: 0.75rem 2rem;
          font-size: 22px;
          border-radius: 5px;
          box-shadow: rgb(31, 1, 72) 0px 20px 30px -10px;
          font-style: italic;
          font-family: "Cursive";
          letter-spacing: 0.7px;
          font-weight: 700;
          margin-top: 14px;
          border: 1px solid #0ef;
          width: 100%;
        }
      }
    }
  }
}

.service-list-modal {
  position: absolute;
  top: 5rem;
  right: 20px;
  width: 650px;
  padding: 30px 10px;
  z-index: 9999999999;
background-color: #fff;
  .service-list {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    li {
      padding: 10px 20px;
      cursor: pointer;
      font-family: "Times New Roman", Times, serif;
      letter-spacing: 1px;
      font-weight: 600;
      font-size: 16px;
      background-color: rgb(237, 248, 249);
      border-radius: 4px;
      box-shadow: 0px 4px 6px rgb(236, 246, 247);
      margin: 4px;
      transition: 0.3s;
      width: 300px;
      color: black;
      &:hover {
        background-color: rgb(210, 247, 250);
        color: #fa8510;
      }
    }
  }
}

.user-popup-box{
  width: 180px;
  padding: 10px;
  border-radius:0 0 0  8px;
  z-index: 9999999;
  position: absolute;
  right: 0;
  top: 80px;
  background-color: #fff;
  box-shadow: 0px 4px 6px rgb(236, 246, 247);

  display: flex;
  flex-direction: column;
  gap: 10px;
  @media (max-width:1000px){
    display: none;
  }
  .box{
    width: 100%;
    display: flex;
    align-items: center;
    gap: 20px;
    cursor: pointer;
    justify-content: start;
    .text{
      color: black;
      font: 18px;
      font-weight: 600;
    }
  }
}