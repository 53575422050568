.cart-container-wrapper {
  padding: 3rem 2rem;
  display: flex;
  flex-direction: column;
  gap: 20px;
  .heading-box {
    .title {
      color: black;
      font-size: 30px;
      letter-spacing: 0.5px;
      font-weight: 600;
      font-family: "Raleway", arial, sans-serif;
    }
  }
  .cart-container-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    @media (max-width: 600px) {
      justify-content: center;
    }
  }
  .payment-box {
    display: flex;
    flex-direction: column;
    gap: 15px;
    .pay-info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .total-price {
        text-align: left;
        font-size: 16px;
        letter-spacing: 0.5px;
        font-weight: 600;
        font-family: "Raleway", arial, sans-serif;
      }
    }
    .payment-button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
    }
  }
}
