.events-container {
  padding: 40px 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
}
.create-event {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 10px 20px;
  background-color: #2def4d;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  color: #fff;
}
.create-event-wrapper {
  width: 100vw;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  .container {
    width: 80%;
    max-width: 1400px;
    padding: 20px;
    display: flex;
    flex-direction: column;

    @media (max-width: 900px) {
      width: 100%;
    }
    .title {
      color: black;
      text-align: center;
      font-size: 24px;
      font-weight: 600;
    }
    .input-wrapper {
      display: flex;
      align-items: start;
      flex-wrap: wrap;
      justify-content: center;
      .input-box {
        width: 100%;
        max-width: 300px;
        margin: 6px;
      }
      .image-box {
        width: 100%;
        padding: 10px;
        border: 1px solid #ccc;
        max-width: 610px;
        border-radius: 5px;
      }
    }
    .submit-button {
      width: 100%;
      max-width: 120px;
      height: 35px;
      margin: 20px auto;
      padding: 10px 20px;
      background-color: #2def4d;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      font-size: 16px;
      color: #fff;
    }
  }
}
