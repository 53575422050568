.info-card{
padding: 10px;
border-radius: 8px;
width: 280px;
height: 120px;
display: flex;
flex-direction: column;
justify-content: center;
margin: 4px;
@media (max-width:576px) {
    width: 200px;
    padding: 10px 6px;

}
@media (max-width:426px) {
    width: 175px;
    padding: 10px 3px;

}
.heading{
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
    .icons{
        width: 28px;
        height: 28px;
        @media (max-width:576px) {
            width: 24px;
            height: 24px;        
        }
    }
    .head{
        font-size: 20px;
        font-weight: 600;
        @media (max-width:576px) {
            font-size: 18px;

        }
    }
}
.info{
    font-size: 24px;
    font-weight: 700;
    width: 100%;
    text-align: center;
    margin-top: 12px;
}
}
.admin-dashboard-wrapper{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 10px 4px;
}
.info-card-container{
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @media (max-width:576px) {
        justify-content: space-evenly;

    }
}
.graph-container{
    width: 100%;
    display: flex;
    align-items: center;
    @media (max-width:900px) {
        flex-direction: column;
    }
    .graph{
        width: 50%;
        @media (max-width:900px) {
            width: 100%;
        }
    }
}
.loader-admin{
    width: 100vw;
    display: flex;
    flex-direction:column;
    gap: 20px;
    .card{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 10px;
    }
}