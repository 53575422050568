.all-service-conainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  background-color: #f4f7fb;
  padding: 10px;
  @media (max-width: 560px) {
    padding: 10px 2px;
  }
  .heading-name {
    font-size: 35px;
    letter-spacing: 0.5px;
    font-weight: 700;
    font-family: "Raleway", arial, sans-serif;
    color: #f09d1b;
    @media (max-width: 500px) {
      font-size: 25px;
    }
  }
  .about-service {
    font-size: 22px;
    letter-spacing: 0.5px;
    font-weight: 700;
    color: black;
    font-family: "Raleway", arial, sans-serif;
  }
  .card-wrapper {
    display: flex;
    align-items: start;
    justify-content: center;
    flex-wrap: wrap;
  }
}

.service-home {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  height: 600px;
  background-color: #f4f7fb;
  padding: 10px;
  @media (min-width: 1400px) {
    height: 650px;
  }
  @media (max-width: 900px) {
    flex-direction: column-reverse;
    height: unset;
  }
  .banner-image {
    width: 60%;
    height: 100%;
    @media (max-width: 900px) {
      width: 100%;
    }
    .hero-bg {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .serivice-text {
    width: 40%;
    max-width: 800px;
    display: flex;
    align-items: start;
    gap: 22px;
    flex-direction: column;
    margin-bottom: 35px;
    @media (max-width: 900px) {
      width: 100%;
    }
    .heading {
      font-size: 28px;
      color: #f4f7fb;
      letter-spacing: 1px;
      font-weight: 600;
      font-family: "Raleway", arial, sans-serif;
    }
    .main-heading {
      font-size: 40px;
      color: #444342;
      letter-spacing: 0.7px;
      font-weight: 700;
      word-spacing: 1.5px;
      font-family: cursive;
      font: italic;
      @media (max-width: 500px) {
        font-size: 30px;
      }
      &.service {
        color: #f09d1b;
        font-size: 52px;
        font-family: "Raleway", arial, sans-serif;

        @media (max-width: 500px) {
          font-size: 36px;
        }
      }
    }
    .about {
      font-size: 24px;
      color: #2b2b2b;
      letter-spacing: 0.5px;
      font-weight: 500;
      font-family: "Raleway", arial, sans-serif;
    }
  }
}

.customer-review-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  background-image: url("../image/png/review-bk.png");
  background-size: cover;
  background-repeat: no-repeat;
  padding: 1rem 2px;
  .heading {
    font-size: 30px;
    letter-spacing: 0.5px;
    font-weight: 600;
    color: #fff;
    font-family: "Raleway", arial, sans-serif;
    width: 340px;
    @media (min-width: 500px) {
      width: unset;
    }
    &.main {
      color: #f09d1b;
    }
  }
  .desc {
    font-size: 18px;
    letter-spacing: 0.5px;
    font-weight: 500;
    color: #fff;
    font-family: "Raleway", arial, sans-serif;
  }
}

.service-about-container {
  width: 100%;
  margin: 20px 0px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 25px;
  .heading-about {
    font-size: 36px;
    letter-spacing: 0.5px;
    font-weight: 700;
    font-family: "Raleway", arial, sans-serif;
    color: #f09d1b;
  }
  .card-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }
}
.ongoing-wrapper {
  width: 100%;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  display: flex;
  padding: 10px;

  @media (max-width: 380px) {
    justify-content: center;
    padding: 10px 2px;
  }
}

.worked-company-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  background-color: #ffefd8;
  padding: 10px;
  @media (max-width: 560px) {
    padding: 10px 2px;
  }
  .text {
    font-size: 36px;
    color: black;
    letter-spacing: 0.7px;
    font-weight: 700;
    font-family: "Raleway", arial, sans-serif;
    word-spacing: 1px;
    text-align: center;
    .active {
      color: #f09d1b;
    }
  }
  .company-logo-box {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
    .company-logo {
      width: 285px;
      height: 185px;
      margin: 10px 16px;

      .image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 8px;
      }
    }
  }
}
