.order-main-wrapper {
  width: 100%;
  padding: 10px 4px;
}


.order-card-wrapper {
  width: 100%;
  background-color: #fff;
  border: 3px solid #f9f1d2;

  border-radius: 8px;
  padding: 10px 2px;
  margin: 6px 0px;
  display: flex;
  flex-direction: column;
  .order-time {
    font-size: 16px;
    font-weight: 500;
    color: black;
    letter-spacing: 0.2px;

    .time {
      font-weight: 700;
    }
  }
  .order-item-box{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
     flex-wrap: wrap;
     gap: 10px;
  .order-item {
    border: 1px solid #f9f1d2;
    box-shadow: 4px 2px 6px #faf9f5;
    margin: 4px 0px;
    width: 100%;
    height: 150px;
    display: flex;
    justify-content: space-between;
    background-color: #faf9f5;
    border-radius: 8px;
    overflow: hidden;
    max-width: 500px;
    .image-container {
      width: 33%;
      max-width: 190px;
      .poster {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .info-container {
      padding: 12px 4px 12px 6px;
      display: flex;
      height: 100%;
      justify-content: space-between;
      flex-direction: column;
      gap: 6px;
      width: 67%;
      .cart-name {
        color: rgb(160, 21, 21);
        font-size: 20px;
        font-weight: 700;
      }
      .total-price {
        color: #000;
        font-size: 16px;
        font-weight: 500;
        font-family: "Raleway", arial, sans-serif;

        .price {
          color: green;
          font-size: 18px;
          font-weight: 600;
          font-family: "Raleway", arial, sans-serif;
        }
      }
    }
  }
}
}
