.product-page-wrapper{
    width: 100vw;
    padding: 40px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

}
.product-container{
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 5px;
}