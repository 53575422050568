@import url("../variable.scss");
.servicecard {
  margin: 15px;
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 10px;
  @media (max-width: 700px) {
    width: 240px;
  }
  @media (max-width: 560px) {
    width: 200px;
    gap: 2px;
  }
  @media (max-width: 500px) {
    width: 150px;
    gap: 1px;
  }
  .service-name {
    font-size: 24px;
    letter-spacing: 0.5px;
    font-weight: 600;
    padding-left: 4px;
    color: black;
    font-family: "Raleway", arial, sans-serif;
    margin-top: 4px;
    cursor: pointer;
    @media (max-width: 500px) {
      font-size: 16px;
    }
  }
  .card-inside {
    position: relative;
    width: 100%;
    height: 310px;
    @media (max-width: 560px) {
      height: 240px;
    }
    @media (max-width: 500px) {
      height: 180px;
    }
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    perspective: 1000px;
    transition: all 3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    border: 3.5px solid transparent;
    animation: borderMove 3s linear infinite;

    background-image: linear-gradient(white, white),
      linear-gradient(
        90deg,
        rgb(4, 134, 144),
        rgb(11, 95, 101),
        rgb(7, 95, 103),
        rgb(5, 105, 112)
      );
    background-origin: border-box;
    background-clip: content-box, border-box;

    @keyframes borderMove {
      from {
        background-position: 0 0;
      }
      to {
        background-position: 100% 0;
      }
    }

    .image {
      width: 100%;
      height: 100%;
      fill: #333;
      transition: all 3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    }

    &:hover {
      transform: scale(1.05);
      box-shadow: 0 8px 16px rgba(255, 255, 255, 0.2);

      .image {
        scale: 0;
      }

      .card__content {
        transform: rotateX(0deg);
      }
    }

    .card__content {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding: 20px;
      box-sizing: border-box;
      background: linear-gradient(
        137deg,
        rgb(27, 49, 51),
        rgb(9, 67, 71),
        rgb(17, 75, 80),
        rgb(9, 67, 71),
        rgb(27, 49, 51)
      );
      transform: rotateX(-90deg);
      transform-origin: bottom;
      transition: all 3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    }

    .card__title {
      margin: 0;
      font-size: 24px;
      color: #fff;
      font-weight: 700;
      font-family: "Raleway", arial, sans-serif;
      @media (max-width: 500px) {
        font-size: 18px;
      }
    }

    .card__description {
      margin: 10px 0 0;
      font-size: 14px;
      color: #fff;
      line-height: 1.4;
      font-family: "Raleway", arial, sans-serif;
      @media (max-width: 500px) {
        font-size: 12px;
      }
    }
  }
}

.review-card-container {
  display: flex;
  flex-direction: column;
  padding: 15px 10px;
  background-color: #ffefd8;
  border-radius: 1rem;
  width: 300px;
  height: 200px;
  // border: 2px solid #0ef;

  .user-box {
    display: flex;
    align-items: start;
    gap: 6px;

    border-bottom: 1px dotted #f09d1b;
    .user-icon {
      font-size: 3rem;
      color: #605e5e;
      cursor: pointer;
    }
    .user-info {
      display: flex;
      align-items: start;
      gap: 8px;
      padding-top: 4px;
      flex-direction: column;
      .name {
        font-size: 14px;
        letter-spacing: 0.5px;
        font-weight: 600;
        color: #f09d1b;
        font-family: "Raleway", arial, sans-serif;
      }
      .address {
        font-size: 14px;
        font-weight: 400;
        color: black;
        font-family: "Raleway", arial, sans-serif;
      }
    }
  }
  .content {
    display: flex;
    align-items: start;
    flex-direction: column;
    gap: 6px;
    margin-top: 4px;
    .rating {
      align-items: center;
      display: flex;
      justify-content: center;
      width: 100%;
    }
    .service-type {
      font-size: 18px;
      letter-spacing: 0.5px;
      font-weight: 600;
      color: #f09d1b;
      font-family: "Raleway", arial, sans-serif;
    }

    .description {
      font-size: 14px;
      letter-spacing: 0.5px;
      font-weight: 400;
      color: black;
      font-family: "Raleway", arial, sans-serif;
    }
  }
}

.event-card-container {
  margin: 20px 35px 20px 0px;
  border: 2px solid rgb(144, 143, 145);
  border-radius: 10px;
  background-color: #f9f4e5;

  @media (max-width: 600px) {
    margin: 0px 0px 20px 0px;
  }
  .price-details {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 5px;
    .one {
      display: flex;
      align-items: center;
      gap: 10px;
      .live {
        background-color: green;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        animation: fadeInOut 3s ease-in-out infinite;
      }
      .price {
        font-size: 20px;
        color: #000;
        font-weight: 600;
        letter-spacing: 0.5px;
        font-family: "Raleway", arial, sans-serif;
      }
    }
  }
  @keyframes fadeInOut {
    0%,
    100% {
      opacity: 0.5;
    }
    50% {
      opacity: 1;
    }
  }
  .poster-container {
    width: 100%;
    height: 150px;
    .poster {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 10px 10px 0px 0px;
    }
  }
  .booking-details {
    width: 350px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: start;
    gap: 8px;
    padding: 10px 5px;

    .box {
      display: flex;
      align-items: center;
      gap: 12px;
      width: 100%;
      padding: 2px 5px;
      .text {
        font-size: 14px;
        color: #000;
        font-weight: 500;
        font-family: "Raleway", arial, sans-serif;
      }
    }
    .more {
      text-align: left;
      width: 100%;
      color: red;
      font-size: 16px;
      cursor: pointer;
      margin-left: 20px;
    }
  }
  .event-book-box {
    display: flex;
    flex-direction: column;
    padding: 6px 4px;
    width: 100%;
    border-top: 2px solid red;
    background-color: #f7efd2;
    border-radius: 0px 0px 10px 10px;

    .event-book {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      .cart-number-box {
        display: flex;
        align-items: center;
        gap: 8px;
        .box {
          width: 40px;
          height: 40px;
          align-content: center;
          background-color: rgb(160, 21, 21);
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          transition: 1s;
          border-radius: 8px;
          .number {
            font-weight: 600;
            font-size: 20px;
            color: #fff;
          }
        }
      }
    }
  }
}

.Work-strategy-card-container {
  margin: 10px 8px;
  background: linear-gradient(
    137deg,
    rgb(14, 13, 16),
    rgb(23, 1, 54),
    rgb(14, 13, 16)
  );
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  border-bottom: 5px solid rgb(2, 136, 146);
  width: 300px;
  padding: 30px 20px;
  height: 340px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);

  .icon-box {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: #0ef;
    align-items: center;
    display: flex;
    justify-content: center;
    .icon {
      width: 50px;
      height: 50px;
    }
  }
  .heading {
    font-size: 24px;
    letter-spacing: 0.5px;
    font-weight: 600;
    padding-left: 4px;
    color: rgb(205, 244, 247);
    font-family: "Raleway", arial, sans-serif;
  }
  .para {
    font-size: 18px;
    letter-spacing: 0.2px;
    font-weight: 400;
    color: #fff;
    font-family: "Raleway", arial, sans-serif;
    text-align: center;
  }
}
.project-card-wrapper {
  width: 500px;
  height: 350px;
  margin-right: 5px;
  margin-bottom: 25px;
  overflow: scroll;
  border-radius: 10px;
  scroll-behavior: smooth;

  @media (max-width: 600px) {
    height: 290px;
  }

  .project-card {
    width: 100%;
  }
}

.cart-card-container {
  border: 3px solid #f9f1d2;
  box-shadow: 4px 2px 6px #faf9f5;
  margin: 12px 4px;
  width: 400px;
  height: 150px;
  display: flex;
  justify-content: space-between;
  background-color: #faf9f5;
  @media (max-width: 810px) {
    width: 380px;
  }
  @media (max-width: 460px) {
    width: 360px;
  }
  .image-container {
    width: 33%;
    .poster {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .info-container {
    padding: 12px 4px 12px 6px;
    display: flex;
    height: 100%;
    justify-content: space-between;
    flex-direction: column;
    gap: 6px;
    width: 67%;
    .cart-name {
      color: rgb(160, 21, 21);
      font-size: 20px;
      font-weight: 700;
    }
    .cart-venue {
      font-size: 14px;
      color: #000;
      font-weight: 500;
      font-family: "Raleway", arial, sans-serif;
    }
    .cart-math {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 2px 0px;
      .total-price {
        color: #000;
        font-size: 16px;
        font-weight: 500;
        font-family: "Raleway", arial, sans-serif;

        .price {
          color: green;
          font-size: 18px;
          font-weight: 600;
          font-family: "Raleway", arial, sans-serif;
        }
      }
      .cart-number-box {
        display: flex;
        align-items: center;
        gap: 8px;
        .box {
          width: 25px;
          height: 25px;
          padding: 4px;
          align-content: center;
          background-color: rgb(160, 21, 21);
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          transition: 1.5s;
          border-radius: 8px;
          .number {
            font-weight: 600;
            font-size: 18px;
            color: #fff;
          }
        }
      }
    }
  }
}

.number-box {
  width: 220px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  margin: 4px;

  @media (max-width: 480px) {
    width: 180px;
    margin-right: 2px;
    padding: 10px 2px;
  }
  .number {
    font-size: 52px;
    color: #f09d1b;
    font-weight: 600;
    font-family: "Raleway", arial, sans-serif;
    letter-spacing: 1px;
    @media (max-width: 480px) {
      font-size: 38px;
    }
  }
  .text {
    font-size: 20px;
    color: #000000;
    font-weight: 600;
    letter-spacing: 0.3px;
    font-family: "Poppins", sans-serif;
    @media (max-width: 480px) {
      font-size: 16px;
    }
  }
}

.team-card-wrapper {
  padding: 2px;
  .card {
    width: 240px;
    height: 270px;
    background: rgb(39, 39, 39);
    border-radius: 12px;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.123);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    transition-duration: 0.5s;
    overflow: hidden;
    margin: 4px;
    &:hover {
      background-color: rgb(43, 43, 43);
      transition-duration: 0.5s;
      scale: 1.1;
    }

    .profileImage {
      width: 100%;
      height: 200px;
      box-shadow: 5px 10px 20px rgba(0, 0, 0, 0.329);

      .image {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }

    .textContainer {
      width: 100%;
      height: 70px;
      text-align: left;
      padding: 6px;
      display: flex;
      flex-direction: column;

      gap: 3px;

      .name {
        font-size: 0.9em;
        font-weight: 600;
        color: white;
        letter-spacing: 0.5px;
      }

      .profile {
        font-size: 0.84em;
        color: rgb(194, 194, 194);
        letter-spacing: 0.2px;
      }
    }
  }
}

.product-card-wrapper {
  max-width: 300px;
  margin: 0 auto;
  background-color:#ffefd8;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  
  .card-header {
    background-color: rgb(204, 249, 204);
    text-align: start;
    padding: 4px;
    color: black;
    display: flex;
    flex-direction: column;
    .title {
      font-size: 1.25rem;
      font-weight: bold;
    }
    
    .subtitle {
      font-size: 0.875rem;
    }
  }
  
  .card-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  
  .card-body {
    padding: 6px 4px;

    .price-rating {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .price {
        font-size: 1.5rem;
        font-weight: bold;
      }

      .rating {
        color: #facc15; // Yellow
      }
    }

    .sizes,
    .colors {
      margin-top: 1rem;

      .label {
        font-size: 0.875rem;
        font-weight: bold;
      }

      .options {
        margin-top: 0.5rem;
        display: flex;
        gap: 0.5rem;

        button {
          padding: 0.5rem 0.75rem;
          border-radius: 0.25rem;
          background-color: rgb(204, 249, 204);
          color:black;
          cursor: pointer;
          border: none;

          &:hover {
            background-color: rgb(204, 249, 204);
          }
        }

        .color {
          width: 1.5rem;
          height: 1.5rem;
          border-radius: 50%;
          border: 1px solid black;
          cursor: pointer;

          &.purple {
            background-color: #7c3aed;
          }

          &.black {
            background-color: #000;
          }
        }
      }
    }

    .quantity-cart {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 1rem;

      .quantity {
        display: flex;
        align-items: center;
        gap: 0.5rem;

        button {
          padding: 0.25rem 0.5rem;
          background-color: rgb(246, 208, 246);
          color: black;
          border: none;
          border-radius: 0.25rem;
          cursor: pointer;

          &:hover {
            background-color: rgb(245, 176, 245);
          }
        }

        .quantity-value {
          font-size: 1rem;
          font-weight: bold;
        }
      }

      .add-to-cart {
        padding: 0.5rem 1rem;
        background-color: rgb(152, 70, 70);
        color: #fff;
        border-radius: 0.25rem;
        border: none;
        cursor: pointer;

        &:hover {
          background-color: rgb(253, 223, 223);
          color: black;

        }
      }
    }
  }
}
