.input-wrapper {
  margin: 10px 0;
  .label {
    color: rgb(63, 63, 63);
    font-size: 16px;
    margin-bottom: 2px;
    display: block;
    font-weight: 500;
    text-align: left;
    width: 100%;
  }
  .input-field {
    width: 100%;
    padding: 8px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    transition: border-color 0.3s ease;
    height: 38px;
    &:focus {
      border-color: #007bff; // Blue border on focus
      outline: none;
    }

    &.input-error {
      border-color: #e63946; // Red border for error
    }
  }

  .error-text {
    color: #e63946;
    font-size: 12px;
    margin-top: 4px;
    display: block;
    font-weight: 400;
    text-align: left;
    width: 100%;
  }
}
