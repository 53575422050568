.slider-container {
  width: 95%;
  // background-image: linear-gradient(
  //   180deg,
  //   rgb(19, 106, 114),
  //   rgb(9, 67, 71),
  // );
  padding: 5px;
  box-sizing: border-box;
  margin: 10px 0px 10px 0px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  //box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);
  justify-content: center;

  .heading {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fa8510;
    font-size: 35px;
    word-spacing: 1px;
    font-weight: 600;
    margin: 0px 0px 15px 0px;
    text-shadow: 0px 1px 5px rgb(14, 13, 16);
  }

  .slick-slider {
    width: 98%;

    @media (max-width: 500px) {
      width: 90%;
    }

    @media (max-width: 500px) {
      .slider-container {
        overflow: hidden;
      }

      .slick-slide {
        opacity: 0;
        transition: opacity 0.5s ease;
      }

      .slick-center {
        opacity: 1 !important;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
      }
    }
  }
}

.button {
  position: relative;
  transition: all 0.3s ease-in-out;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
  padding-block: 0.5rem;
  padding-inline: 1.25rem;
  background-color: #0187fc;
  border-radius: 9999px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffff;
  gap: 10px;
  font-weight: bold;
  border: 3px solid #ffffff4d;
  outline: none;
  overflow: hidden;
  font-size: 15px;
  cursor: pointer;

  .icon {
    width: 24px;
    height: 24px;
    transition: all 0.3s ease-in-out;
  }

  &:hover {
    transform: scale(1.05);
    border-color: #fff9;

    .icon {
      transform: translate(4px);
    }

    &::before {
      animation: shine 1.5s ease-out infinite;
    }
  }

  &::before {
    content: "";
    position: absolute;
    width: 100px;
    height: 100%;
    background-image: linear-gradient(
      120deg,
      rgba(255, 255, 255, 0) 30%,
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0) 70%
    );
    top: 0;
    left: -100px;
    opacity: 0.6;
  }
}

@keyframes shine {
  0% {
    left: -100px;
  }

  60%,
  100% {
    left: 100%;
  }
}

.contact-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 1rem 4rem;
  flex-direction: column;
  background-color: #f4f7fb;

  .text {
    font-size: 36px;
    color: black;
    letter-spacing: 0.7px;
    font-weight: 700;
    font-family: "Raleway", arial, sans-serif;
    word-spacing: 1px;
    text-align: center;
    &.active {
      color: #f09d1b;
    }
  }

  .details-box {
    display: flex;
    align-items: start;
    width: 100%;
    justify-content: space-between;
    margin-top: 20px;
    @media (max-width: 800px) {
      flex-direction: column;
      align-items: center;
    }
    .cotact-container-box {
      display: flex;
      align-items: start;
      flex-direction: column;
      gap: 35px;
      justify-content: start;
      padding: 10px;
      margin: 10px 0px;
      .box {
        width: 360px;
        display: flex;
        align-items: center;
        gap: 20px;
        @media (max-width: 600px) {
          gap: 8px;
          height: unset;
        }
        .icon {
          width: 40px;
          height: 40px;
          @media (max-width: 600px) {
            width: 20px;
            height: 20px;
          }
        }
        .detail {
          color: black;
          letter-spacing: 0.7px;
          font-weight: 700;
          font-family: "Raleway", arial, sans-serif;
          word-spacing: 1px;
          //font-size: 24px;

          @media (max-width: 900px) {
            font-size: 22px;
            word-spacing: 0.3px;
            letter-spacing: 0.2px;
          }
          @media (max-width: 600px) {
            font-size: 18px;
            word-spacing: 0.3px;
            letter-spacing: 0.2px;
          }
        }
      }
    }

    .contact-form-box {
      width: 40%;
      .contact-form {
        margin: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .name,
        .email,
        .service,
        .phone {
          font-size: medium;
          width: 400px;
          height: 50px;
          margin: 0.5rem;
          padding: 0.5rem 1rem;
          color: white;
          border: 1px ridge #0ef;
          border-radius: 5px;

          background: linear-gradient(
            180deg,
            rgb(14, 13, 16),
            rgb(23, 1, 54),
            rgb(14, 13, 16)
          );
          @media (max-width: 500px) {
            width: 360px;
          }
        }
        .name::placeholder,
        .message::placeholder,
        .email::placeholder,
        .service::placeholder,
        .phone::placeholder {
          color: white;
          font-size: medium;
        }
        .message {
          font-size: medium;
          width: 100%;
          width: 400px;
          margin: 0.5rem;
          padding: 0.5rem 1rem;
          color: white;
          border: 1px ridge #0ef;
          border-radius: 5px;
          // box-shadow:-4px 6px 12px rgb(54, 2, 129);

          background: linear-gradient(
            180deg,
            rgb(14, 13, 16),
            rgb(23, 1, 54),
            rgb(14, 13, 16)
          );
          @media (max-width: 500px) {
            width: 360px;
          }
        }
        .submitbtn {
          color: #0ef;
          background: linear-gradient(
            45deg,
            rgb(14, 13, 16),
            rgb(31, 1, 72),
            rgb(14, 13, 16)
          );
          border: none;
          cursor: pointer;
          padding: 0.75rem 2rem;
          font-size: 22px;
          border-radius: 5px;
          box-shadow: rgb(31, 1, 72) 0px 20px 30px -10px;
          font-style: italic;
          font-family: "Cursive";
          letter-spacing: 0.7px;
          font-weight: 700;
          margin-top: 14px;
          border: 1px solid #0ef;
          width: 400px;
          @media (max-width: 500px) {
            width: 360px;
          }
        }
      }
    }
  }
}

.CartBtn {
  width: 140px;
  height: 40px;
  border-radius: 8px;
  border: none;
  background-color: rgb(147, 2, 2);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition-duration: 0.5s;
  overflow: hidden;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.103);
  position: relative;
  margin: 8px 0px;

  &:hover {
    .IconContainer {
      transform: translateX(58px);
      border-radius: 40px;
      transition-duration: 0.5s;
    }

    .text {
      transform: translate(10px, 0);
      transition-duration: 0.5s;
    }
  }

  &:active {
    transform: scale(0.95);
    transition-duration: 0.5s;
  }

  .IconContainer {
    position: absolute;
    left: -50px;
    width: 30px;
    height: 30px;
    background-color: transparent;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    z-index: 2;
    transition-duration: 0.5s;
    .icon {
      border-radius: 1px;
    }
  }

  .text {
    height: 100%;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    z-index: 1;
    transition-duration: 0.5s;
    font-size: 14px;
    font-weight: 600;
  }
}


.table-container {
  width: 100%;
  overflow-x: auto;
  margin: 20px 2px;
border: 2px solid rgb(243, 243, 243);
border-radius: 8px;
max-height: 500px;
.responsive-table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  text-align: left;
thead{
  border: 1px solid rgb(247, 241, 232);
  background-color: rgb(253, 233, 197);
height: 60px;
border-radius: 8px 8px 0px 0px;
box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px;
}
tbody{
  tr:nth-child(odd) {
    background-color: #fff;
  }

  tr:nth-child(even) {
    background-color: rgb(253, 250, 243);
  }
  cursor: pointer;
}
  th, td {
    padding: 10px;
   min-width: 100px;
  }


  .user-info {
    display: flex;
    align-items: center;

    .profile-pic {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin-right: 10px;
    }
  }

  @media (max-width: 768px) {
    th, td {
      font-size: 14px;
      padding: 8px;
    }

    .profile-pic {
      width: 30px;
      height: 30px;
    }
  }

  @media (max-width: 480px) {
    th, td {
      font-size: 12px;
      padding: 6px;
    }

    .profile-pic {
      width: 25px;
      height: 25px;
    }
  }
}
}