.about-main-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;

  .about-home {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 30px 12px;
    margin: 0 0 15px 0px;
    background-color: #f4f7fb;

    @media (max-width: 700px) {
      flex-direction: column;
    }

    .about-text {
      display: flex;
      align-items: start;
      gap: 22px;
      flex-direction: column;
      margin-bottom: 10px;

      .main-heading {
        font-size: 26px;
        letter-spacing: 0.7px;
        font-weight: 600;
        font-family: "Raleway", arial, sans-serif;
        word-spacing: 1.5px;
        color: black;
        &.special {
          color: #f09d1b;
        }
        @media (max-width: 420px) {
          font-size: 18px;
        }
      }
      .title {
        color: #f09d1b;
        font-size: 41px;
        letter-spacing: 0.7px;
        font-weight: 700;
        font-family: "Raleway", arial, sans-serif;
        word-spacing: 1.5px;
        @media (max-width: 420px) {
          font-size: 22px;
        }
      }
      .about {
        font-size: 25px;
        color: black;
        letter-spacing: 0.5px;
        font-weight: 500;
        font-family: "Raleway", arial, sans-serif;
        @media (max-width: 420px) {
          font-size: 14px;
        }
      }
    }
    .image-container {
      .image {
        width: 300px;
        height: 300px;
        .img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
}

.ceo-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  .ceo-home {
    width: 100%;
    display: flex;
    align-items: start;
    justify-content: space-between;
    gap: 4px;
    padding: 20px 10px;
    margin: 15px 0px;

    @media (max-width: 800px) {
      flex-direction: column;
    }

    .image-container {
      border: 6px solid #1f95fd;
      padding: 20px;
      @media (max-width: 420px) {
        padding: 8px;
      }
      .image {
        width: 370px;
        height: 370px;
        .img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        @media (max-width: 420px) {
          width: 340px;
          height: 340px;
        }
      }
    }
    .ceo-text {
      display: flex;
      align-items: start;
      gap: 10px;
      flex-direction: column;
      margin-left: 15px;

      .title {
        color: #f09d1b;
        font-size: 32px;
        letter-spacing: 0.7px;
        font-weight: 700;
        font-family: "Raleway", arial, sans-serif;
        word-spacing: 1.5px;
        margin-top: 10px;
        @media (max-width: 420px) {
          font-size: 25px;
        }
      }
      .about {
        font-size: 22px;
        color: black;
        font-weight: 500;
        font-family: "Raleway", arial, sans-serif;
        margin-bottom: 3px;
        @media (max-width: 420px) {
          font-size: 16px;
        }
      }
    }
  }
}
.conatiner {
  width: 100%;
  background: linear-gradient(265deg, #3b95a6, #7bbfca, #3b95a6);
  .heading {
    color: black;
    font-size: 42px;
    letter-spacing: 0.7px;
    font-weight: 700;
    font-family: "Raleway", arial, sans-serif;
    word-spacing: 1.5px;
    padding: 10px 0px;
    display: flex;
    justify-content: center;
    width: 100%;
    .hero {
      color: #f09d1b;
    }
  }
  .feature-projects {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 20px;
    flex-direction: column;
    //background-image: url("../image/jpeg/projectbg.jpg");
    // background-size: contain;
    // background-repeat: no-repeat;
    @media (min-width: 1085px) {
      background-size: cover;
    }

    .project-container {
      display: flex;
      align-items: start;
      justify-content: space-evenly;
      flex-wrap: wrap;
      width: 100%;
      padding: 10px;

      @media (max-width: 800px) {
        justify-content: center;
      }
    }
  }
}
.team-wrapper {
  width: 100%;
  margin: 1rem 0rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1.5rem;
  .heading {
    color: black;
    font-size: 36px;
    letter-spacing: 0.7px;
    font-weight: 700;
    font-family: "Raleway", arial, sans-serif;
    word-spacing: 1.5px;
    margin-top: 10px;
    .hero {
      color: #f09d1b;
    }
  }
  .team-box-wrapper {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    align-items: center;
    padding: 10px;
    justify-content: space-around;
  }
}
